<template>
  <section id="introduction">
    <div class="w1200">
      <div class="intro">
        <!-- <h3 class="eng">{{titleEn}}</h3> -->
        <h5 class="txt-bold">
          {{titleZh}}
          <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
        </h5>
        <p class="small" :class="{'txt-left': getLanguage === 'en-US'}">
          {{intro}}
        </p>
      </div>
      <div class="itembox">
        <div class="item"
          v-for="(item, key) in items"
          :key="key"
          @click="clickItem(item.type, item.parameter);">
          <div class="icon"
            :style="{backgroundImage: `url(${item.pic})`}">
          </div>
          <h6 class="txt-bold">{{item.title}}</h6>
          <p class="info small" v-html="item.brief_info"></p>
          <div class="btn btn-white">{{$t('more')}}</div>
        </div>
      </div>
    </div>

    <div id="introduction_lightbox"
      v-show="lightboxOpen">
      <div class="cover" @click="clickCloseLightbox()"></div>
      <div class="box">
        <img src="@/assets/img/x.svg" alt="" class="close"
          @click="clickCloseLightbox()">
        <div class="pic"
          :style="{backgroundImage: `url(${lightboxShow.pic})`}">
        </div>
        <div class="text">
          <h5 class="h5-25 txt-bold">{{lightboxShow.title}}</h5>
          <div class="pointbox">
            <div class="point"
            v-for="(item, key) in lightboxShow.point"
            :key="key">
              <p class="small txt-bold">{{item.title}}：</p>
              <p class="small txt-dark_gray">{{item.info}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AboutIntroduction',
  props: ['titleEn', 'titleZh', 'intro', 'items'],
  data() {
    return {
      lightboxOpen: false,
      lightboxShow: {
        pic: String,
        title: String,
        point: [
          {
            title: String,
            info: String,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  methods: {
    clickItem(type, parameter) {
      switch (type) {
        case 'link':
          if (parameter.class && parameter.id) {
            this.$router.push({ path: `/aboutinfo/${parameter.class}/${parameter.id}` });
          }
          break;
        case 'lightbox':
          if (parameter) {
            this.lightboxOpen = true;
            this.lightboxShow = parameter;
          }
          break;
        default:
          break;
      }
    },
    clickCloseLightbox() {
      this.lightboxOpen = false;
    },
  },
};
</script>
<style>
 .info font {
  font: inherit;
 }
</style>
