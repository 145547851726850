<template>
  <section id="doctor_introduction">
    <div class="top">
      <div class="w1200">
        <div class="text">
          <!-- <h3 class="eng" v-html="titleEn"></h3> -->
          <h5 class="txt-bold">
            {{titleZh}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="w1200">
        <div class="text">
          <p class="small txt-dark_gray pre-line"
          :class="{'txt-left': getLanguage === 'en-US'}"
          >
            {{info}}
          </p>
          <router-link :to="link" class="btn" v-if="$route.name === 'AboutLab'">{{$t('lookingTeams')}}</router-link>
          <router-link :to="link" class="btn" v-else>{{$t('lookingDoctors')}}</router-link>
        </div>
      </div>
    </div>
    <div class="pic_area">
      <div class="inner w1200">
        <div class="pic_outer">
          <figure :style="{backgroundImage: `url(${decoPic})`}"></figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AboutDoctorIntroduction',
  props: ['titleEn', 'titleZh', 'info', 'link', 'decoPic'],
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  data() {
    return {
    };
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
<style lang="scss">
  .bottom {
    .text{
      .pre-line {
        white-space: pre-line;
      }
    }
 }
</style>
