<template>
  <section id="about_more">
    <div class="w1200">
      <!-- <h3 class="eng">MORE NUWA</h3> -->
      <h5 class="txt-bold">
        {{$t('moreNuwa')}}
        <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
      </h5>

      <div class="itembox">
        <div class="item"
          v-for="(item, key) in items"
          :key="key">
          <router-link :to="item.link" class="pic">
            <figure :style="{backgroundImage: `url(${item.pic})`}"></figure>
            <div class="name txt-white">
              <h5
              class="h5-25 txt-bold">{{item.titleEn}}</h5>
              <p v-if="getLanguage !== 'en-US'">{{item.titleZh}}</p>
              <div class="btn btn-solid">{{$t('lookingMore')}}</div>
            </div>
          </router-link>
          <p
          :style="{textAlign: txt}"
          class="text small txt-dark_gray"
          >{{item.info}}</p>
        </div>
      </div>
    </div>

    <p class="deco_text">
      NUWA
    </p>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AboutMore',
  props: ['items'],
  data() {
    return {
      txt: 'center',
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  mounted() {
    if (this.getLanguage === 'en-US') {
      this.txt = 'left';
    }
  },
};
</script>
